import { useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';

export default function Location() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  return (
    <>
      <Container>
        <div>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '40%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Spinner variant='primary' />
            </div>
          )}
          <iframe
            title='Google Maps'
            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d878.0567227270071!2d-81.592957!3d28.3217346!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dd7eb25ba0ba37%3A0xeb86feb315864022!2sWindsor%20Hills%20Resort!5e0!3m2!1sen!2sca!4v1684357027714!5m2!1sen!2sca'
            width='100%'
            height='600'
            style={{ border: '0' }}
            allowFullScreen={false}
            loading='lazy'
            onLoad={() => setIsLoading(false)}
            referrerPolicy='no-referrer-when-downgrade'
          ></iframe>
        </div>
      </Container>
    </>
  );
}
