import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
  Image,
} from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

export default function Layout() {
  return (
    <>
      <Navbar
        expand='lg'
        sticky='top'
        style={{ backgroundColor: 'var(--bs-body-bg)' }}
      >
        <Container>
          <Navbar.Brand>
            <Image
              alt='Windsor Hills Disney Hideaway'
              src='/images/website/cover.png'
              className='logo'
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse
            id='basic-navbar-nav'
            className='justify-content-end'
          >
            <Nav>
              <StyledNavLink href='/'>Home</StyledNavLink>
              <StyledNavLink href='/about'>About</StyledNavLink>
              <StyledNavLink href='/location'>Location</StyledNavLink>
              <StyledNavLink href='/photos'>Photos</StyledNavLink>
              <StyledNavLink href='/reviews'>Reviews</StyledNavLink>
              <StyledNavItem>
                <Button href='/contact'>Contact</Button>
              </StyledNavItem>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
      <footer className='d-flex flex-wrap justify-content-between align-items-center py-3 mt-4'>
        <Container>
          <Row>
            <Col className='d-flex col-auto justify-content-center align-items-center'>
              <Image alt='logo' src='/images/website/logo.png' />
            </Col>
            <Col>
              <ul style={{ listStyle: 'none' }}>
                <StyledLi>
                  <StyledH5>Address</StyledH5>
                  <StyledLink
                    style={{ textDecoration: 'none' }}
                    href='https://goo.gl/maps/U7QmyU8v7PqF2vws7'
                  >
                    2600 N. Old Lake Wilson Rd. Kissimmee, FL 34747
                  </StyledLink>
                </StyledLi>
                <StyledLi>
                  <StyledH5>Call or Text</StyledH5>
                  <StyledLink
                    style={{ textDecoration: 'none' }}
                    href='sms://+15197554101;'
                  >
                    &#40;519&#41; 755-4101{' '}
                    <Image src='/images/flag/ca-flag.jpg' width={30} />
                  </StyledLink>
                  {' or '}
                  <StyledLink
                    style={{ textDecoration: 'none' }}
                    href='https://wa.me/15197554101'
                  >
                    WhatsApp <Image src='/images/flag/us-flag.png' width={30} />
                  </StyledLink>
                </StyledLi>
                <StyledLi>
                  <StyledH5>Email</StyledH5>
                  <StyledLink
                    style={{ textDecoration: 'none' }}
                    href='mailto:huntjoanne@rogers.com'
                  >
                    huntjoanne@rogers.com
                  </StyledLink>
                </StyledLi>
              </ul>
            </Col>
            <Col>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Image
                  src='/images/tinkerbell.png'
                  style={{ maxWidth: '10rem' }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

const StyledLink = styled.a`
  text-decoration: none;
`;

const StyledH5 = styled.h5`
  text-decoration: underline;
`;
const StyledLi = styled.li`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
const StyledNavLink = styled(Nav.Link)`
  margin: 0.5rem;
`;
const StyledNavItem = styled(Nav.Item)`
  margin: 0.5rem;
`;
