import moment from 'moment';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Book() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const showError = (text: string) => {
    toast.error(text, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      progress: undefined,
      theme: 'light',
    });
  };

  const formSubmit = (data: any) => {
    const start_date = moment(data.check_in);
    const end_date = moment(data.check_out);
    const people = Number(data.children) + Number(data.adults);
    if (people > 6) {
      showError('Maximum of 6 people!');
      return;
    }
    if (new Date(data.check_in) > new Date(data.check_out)) {
      showError('Your Check-in date is after your Check-out date!');
      return;
    }

    const subject = `Rentral Request from ${data.full_name} from ${data.check_in} to ${data.check_out}`;
    const message = `Windsor Hills Disney Hideaway\n\nName: ${
      data.full_name
    }\nDate: ${data.check_in} to ${data.check_out} (${end_date.diff(
      start_date,
      'days'
    )} days)\nPeople: ${data.adults} Adults, ${
      data.children
    } Children (${people} Total)\n\n${
      data.message ? data.message : 'No message provided.'
    }\n
    `;

    switch (data.type) {
      case 'email':
        window.open(
          `mailto:huntjoanne@rogers.com?subject=${subject}&body=${message.replaceAll(
            '\n',
            '%0D%0A'
          )}`,
          '_self'
        );
        break;
      case 'text':
        window.open(
          `sms://+15197554101?body=${message.replaceAll('\n', '%0D%0A')}`,
          '_self'
        );
        break;
      case 'whatsapp':
        window.open(
          `https://wa.me/15197554101?text=${message.replaceAll('\n', '%0a')}`,
          '_self'
        );
        break;
    }
  };

  return (
    <>
      <Container>
        <Form
          style={{
            margin: 'auto',
            marginTop: '4rem',
            marginBottom: '4rem',
            maxWidth: '50rem',
          }}
          onSubmit={handleSubmit(formSubmit)}
        >
          <Row className='mb-3' xs={1} md={1} lg={2}>
            <Col>
              <Form.Label>Check-In</Form.Label>
              <Form.Control
                type='date'
                min={moment().format('YYYY-MM-DD')}
                {...register('check_in', {
                  required: true,
                })}
                isInvalid={!!errors?.check_in}
              />
            </Col>
            <Col>
              <Form.Label>Check-Out</Form.Label>
              <Form.Control
                type='date'
                min={moment().format('YYYY-MM-DD')}
                {...register('check_out', {
                  required: true,
                })}
                isInvalid={!!errors?.check_out}
              />
            </Col>
          </Row>
          <Row className='mb-3' xs={1} md={1} lg={2}>
            <Col>
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Peter Pan...'
                {...register('full_name', { required: true })}
                isInvalid={!!errors?.full_name}
              />
            </Col>
            <Col>
              <Row>
                <Col>
                  <Form.Label>Adults</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='0'
                    min='0'
                    max='6'
                    {...register('adults', { required: true })}
                    isInvalid={!!errors?.adults}
                  />
                </Col>
                <Col>
                  <Form.Label>Children</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='0'
                    min='0'
                    max='6'
                    {...register('children', { required: true })}
                    isInvalid={!!errors?.children}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Form.Label>Message</Form.Label>
              <Form.Control
                type='textarea'
                as='textarea'
                placeholder='Type your message here...'
                rows={4}
                {...register('message')}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              {['Email', 'Text', 'WhatsApp'].map((button) => (
                <Button
                  type='submit'
                  className='m-1'
                  style={{ width: '6.5rem' }}
                  onClick={() => {
                    setValue('type', button.toLowerCase());
                  }}
                >
                  {button}
                </Button>
              ))}
            </Col>
          </Row>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
}
