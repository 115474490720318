import { Card, Col, Image } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCol = styled(Col)`
  text-align: center;
`;

export const StyledImage = styled(Image)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  width: 100%;
  aspect-ratio: 16 / 9;
  max-width: 30rem;
`;

export const StyledCard = styled(Card)`
  height: 100%;
`;
