import Zoom from 'react-medium-image-zoom';
import { Container, Image } from 'react-bootstrap';
import 'react-medium-image-zoom/dist/styles.css';

const photos = [
  '/images/room/front-page-pool.webp',
  '/images/room/5f7ff690.f10.webp',
  '/images/room/ac91e7cb.f10.webp',
  '/images/room/d7eb597f.f10.webp',
  '/images/room/8c8baf11.png',
  '/images/room/balcony.webp',
  '/images/room/IMG_8904.JPG',
  '/images/room/bathroom.webp',
  '/images/room/front door.webp',
  '/images/room/kitchen.webp',
  '/images/room/living room.webp',
  '/images/room/tv.webp',
  '/images/room/5ddb29e0.png',
  '/images/room/c0c28eca.png',
  '/images/room/2cc33ae8.png',
];

export default function Photos() {
  return (
    <>
      <Container
        className='d-flex flex-wrap justify-content-center'
        style={{ minHeight: '600px' }}
      >
        {photos.map((photo) => (
          <Zoom IconUnzoom={'div'}>
            <Image alt={photo} src={photo} style={{ maxWidth: '420px' }} />
          </Zoom>
        ))}
      </Container>
    </>
  );
}
