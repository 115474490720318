import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Container, Spinner } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';
import { useCallback, useEffect, useState } from 'react';

const PAGE_SIZE = 3;

export interface Summary {
  reviewCount: number;
  averageRating: number;
}
export interface Review {
  uuid: string;
  headline: string;
  rating: number;
  body: string;
  arrivalDate: string;
  datePublished: string;
  ownershipTransferred: string;
  voteCount: number;
  reviewLanguage: string;
  reviewer: Reviewer;
  response: any;
  source: string;
  unverifiedDisclaimer: any;
  __typename: string;
}

export interface Reviewer {
  location: any;
  nickname: string;
  profileUrl: string;
  __typename: string;
}

const requestPayload = {
  operationName: 'Reviews',
  variables: {
    isInitial: true,
    listingId: '321.515047.1098228',
    page: 1,
    pageSize: PAGE_SIZE,
  },
  query: `query Reviews($isInitial: Boolean = false, $listingId: String!, $page: Int!, $pageSize: Int!) {
    reviews(listingId: $listingId, page: $page, pageSize: $pageSize) {
      uuid
      headline: title
      rating
      body: text
      arrivalDate
      datePublished
      ownershipTransferred
      voteCount
      reviewLanguage
      reviewer {
        location
        nickname
        profileUrl
        __typename
      }
      response: reviewResponse {
        status
        body
        language
        country
        __typename
      }
      source
      unverifiedDisclaimer
      __typename
    }
    reviewSummary(listingId: $listingId) @include(if: $isInitial) {
      reviewCount
      guestbookReviewCount
      averageRating
      verificationDisclaimerLinkText
      verificationDisclaimerLinkUrl
      verificationDisclaimerText
      __typename
    }
  }`,
};

export default function Reviews() {
  const [summary, setSummary] = useState<Summary | null>(null);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [page, setPage] = useState<number>(1);

  const { refetch, isFetching } = useQuery({
    queryKey: ['reviews'],
    queryFn: () => {
      return axios.post('https://www.vrbo.com/mobileapi/graphql', {
        ...requestPayload,
        variables: {
          ...requestPayload.variables,
          page: page,
        },
      });
    },
    onSuccess: (response) => {
      setSummary(response.data.data.reviewSummary);
      setReviews((prev) => [...prev, ...response.data.data.reviews]);
      setPage((prev) => prev + 1);
    },
    refetchOnWindowFocus: false,
  });

  if (!reviews.length || !summary)
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '600px',
          }}
        >
          <Spinner variant='primary' />
        </div>
      </>
    );

  return (
    <>
      <Container>
        <h1 style={{ margin: 0 }}>{summary.reviewCount} Reviews</h1>
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <Rating
              initialValue={summary.averageRating as number}
              allowFraction={true}
              size={45}
              readonly
            />
          </div>
          <span style={{ fontSize: '1.5rem' }}>
            &#40;
            {(summary.averageRating as number).toFixed(1)}
            &#41;
          </span>
        </div>
        {reviews.map((review) => (
          <Card
            key={review.uuid}
            style={{
              marginTop: '1rem',
              marginBottom: '1rem',
            }}
          >
            <Card.Body>
              <Card.Title>
                <h4>
                  <b>{review.headline}</b>
                </h4>
                <Card.Subtitle>
                  <span style={{ fontSize: '0.85rem' }}>
                    by {review.reviewer.nickname}
                  </span>
                </Card.Subtitle>
                <div>
                  <Rating
                    initialValue={review.rating}
                    allowFraction={true}
                    size={25}
                    readonly
                  />
                </div>
              </Card.Title>
              <Card.Text
                style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  textIndent: '0',
                  textAlign: 'left',
                }}
              >
                {review.body}
              </Card.Text>
              <span style={{ fontSize: '0.85rem' }}>
                {new Date(review.datePublished).toDateString()}
              </span>
            </Card.Body>
          </Card>
        ))}
        <div style={{ textAlign: 'center' }}>
          <Button
            hidden={page * PAGE_SIZE >= summary.reviewCount}
            onClick={() => refetch()}
          >
            {isFetching ? <Spinner size='sm' /> : 'Load More'}
          </Button>
        </div>
      </Container>
    </>
  );
}
