import { Button, Carousel, Col, Container, Image, Row } from 'react-bootstrap';
import { StyledCol, StyledImage } from '../Styles';

const photosMain = [
  '/images/room/front-page-pool.webp',
  '/images/room/5f7ff690.f10.webp',
  '/images/room/balcony.webp',
];

const photosRoom = [
  '/images/room/tv.webp',
  '/images/room/living room.webp',
  '/images/room/front door.webp',
];

export default function Home() {
  return (
    <>
      <Container>
        <Carousel interval={null}>
          {photosMain.map((photo) => (
            <Carousel.Item key={photo} style={{ maxHeight: '42rem' }}>
              <div
                className='d-flex justify-content-center'
                style={{ alignItems: 'flex-end' }}
              >
                <Image className='w-100 ratio-16x9' src={photo} alt={photo} />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <Container style={{ marginTop: '2rem' }}>
          <Row xs={1} md={1} lg={2}>
            <Col>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div>
                  <h4 className='text-center'>Tinkerbell's Hideaway</h4>
                  <p>
                    This 2 bedroom, 2 bathroom condo is on the top floor with
                    comfortable furnishings and is quiet for sleeping. An
                    elevator on site. Lots of free parking available for your
                    vehicles. Great value vacation condo sleeps up to 6. The
                    condo includes a fully equipped kitchen with all appliances
                    and utensils. There is a full-size washer and dryer, central
                    air and heat, an iron and ironing board, telephone service,
                    a patio table, and chairs. TV's in all rooms. Less than a
                    minute's walk to the pool and clubhouse.
                  </p>
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      href='/about'
                      style={{
                        width: '100%',
                        maxWidth: '15rem',
                        marginBottom: '1rem',
                      }}
                    >
                      Learn More
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <StyledCol>
              <Carousel interval={null}>
                {photosRoom.map((photo) => (
                  <Carousel.Item>
                    <Image
                      className='w-100 ratio-16x9'
                      src={photo}
                      alt={photo}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </StyledCol>
          </Row>
        </Container>
        <h1
          style={{
            fontWeight: 'bold',
          }}
        >
          Your Resort
        </h1>
        <p>
          Windsor hills resort resides in a gated community, accompanied by a
          heated pool, and a waterpark on your property! The resort is within
          driving range of both Walt Disney World and Universal Studios, being
          the perfect distance between either theme park. The resort comes
          alongside amenities shown below, an arcade, exercise room, pool
          tables, and a tennis court, along with much much more!
        </p>
        <Container>
          <Row xs={1} md={1} lg={2}>
            <StyledCol>
              <StyledImage src='/images/home/0.jpg' />
            </StyledCol>
            <StyledCol>
              <StyledImage src='/images/home/1.jpg' />
            </StyledCol>
          </Row>
          <Row xs={1} md={1} lg={2}>
            <StyledCol>
              <StyledImage src='/images/home/2.jpg' />
            </StyledCol>
            <StyledCol>
              <StyledImage src='/images/home/3.jpg' />
            </StyledCol>
          </Row>
          <Row xs={1} md={1} lg={2}>
            <StyledCol>
              <StyledImage src='/images/home/4.jpg' />
            </StyledCol>
            <StyledCol>
              <StyledImage src='/images/home/5.jpg' />
            </StyledCol>
          </Row>
        </Container>
      </Container>
    </>
  );
}
